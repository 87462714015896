import { User, Database } from ".";
import Model from "./Model";

export default class DatabaseSupervisor extends Model {
  static type = "databaseSupervisors";

  supervisor: User;
  database: Database;

  get fullname() {
    return this.supervisor.firstName + " " + this.supervisor.lastName;
  }
}

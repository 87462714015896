import Vue from "vue";
import Vuex from "vuex";
import http from "./http";
import auth from "./auth";
import database from "./database";
import survey from "./survey";
import currentUser from "./currentUser";

Vue.use(Vuex);
import VuexPersist from "vuex-persist";

const vuexPersist = new VuexPersist({
  key: "basedia",
  storage: window.localStorage,
  modules: ["auth"],
});

export default new Vuex.Store({
  modules: {
    auth,
    currentUser,
    http,
    database,
    survey,
  },
  plugins: [vuexPersist.plugin],
});

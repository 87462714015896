import Model from "./Model";

export default class SubscriptionRequest extends Model {
  static type = "subscriptionRequests";

  status: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: Date;

  get fullname() {
    return this.firstName + " " + this.lastName;
  }
}

export class CreateSubscriptionRequest {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;

  constructor() {
    this.firstName = null;
    this.lastName = null;
    this.email = null;
  }
}

import { Component, Mixins } from "vue-property-decorator";
import { AdminGroupsMixin } from "mixins/http";
import RoutingMixin from "mixins/Routing";
import RequestsMixin from "mixins/http/RequestsMixin";

@Component
class AdminGroupService extends Mixins(
  AdminGroupsMixin,
  RequestsMixin,
  RoutingMixin
) {}

export default new AdminGroupService();

import RegisterHttp from "decorators/RegisterHttp";
import RequestData from "store/types/RequestData";
import { Component, Vue } from "vue-property-decorator";

export const REQUEST_TAG = "confirmation";

@Component
export default class ConfirmationMixin extends Vue {
  @RegisterHttp(REQUEST_TAG) confirmationHttp!: RequestData;

  validateToken(confirmationToken: string) {
    return this.$store.dispatch("http/request", {
      tag: REQUEST_TAG,
      url: this.$urlFor("validate_confirmation", {
        format: "json",
        confirmationToken,
      }),
      method: "GET",
    });
  }

  confirmWithPassword(confirmationToken: string, password: string) {
    return this.$store.dispatch("http/request", {
      tag: REQUEST_TAG,
      url: this.$urlFor("confirm_with_password", { format: "json" }),
      data: { confirmationToken, password },
      method: "POST",
    });
  }
}

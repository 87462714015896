import Vue from "vue";

export function showErrorToast(message: string) {
  showToast(message, { type: "error" });
}

export function showToast(
  message: string,
  config: { position?: string; type?: string; duration?: number } = {
    position: "bottom-right",
    type: "success",
    duration: 2000,
  }
) {
  Vue?.toasted?.show(message, {
    position: config.position || "bottom-right",
    type: config.type || "success",
    duration: config.duration || 1000,
    keepOnHover: true,
  } as any);
}

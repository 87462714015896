import { Question } from ".";
import AnswersGroup from "./AnswersGroup";
import Model from "./Model";
import { QuestionKinds } from "./Question";

export interface KeyValue {
  key: string;
  value: string;
}

export interface KeyValueWithNotes {
  key: string;
  value: string;
  notes: string;
}

export interface KeyValueWithDetails {
  key: string;
  value: string;
  detailsKey: string | null;
  detailsValue: string | null;
}

export type MultiLevelsSingleChoiceAnswer = {
  key?: string;
  value?: string;
  details?: MultiLevelsSingleChoiceAnswer;
};

export type MultiLevelsMultipleChoiceAnswer = MultiLevelsSingleChoiceAnswer[];

export interface AnswerPayload {
  id?: string;
  answersGroupId?: string;
  questionId?: string;
  metadata?:
    | string
    | KeyValue
    | KeyValue[]
    | boolean
    | number
    | null
    | KeyValueWithNotes
    | KeyValueWithNotes[]
    | MultiLevelsSingleChoiceAnswer
    | MultiLevelsMultipleChoiceAnswer;
}

export default class Answer extends Model {
  static type = "answers";

  metadata!:
    | string
    | KeyValue
    | KeyValue[]
    | boolean
    | number
    | null
    | KeyValueWithNotes[]
    | KeyValueWithNotes
    | MultiLevelsSingleChoiceAnswer
    | MultiLevelsMultipleChoiceAnswer;
  kind!: QuestionKinds;
  answersGroup!: AnswersGroup;
  question!: Question;

  get associatedQuestionIds(): string[] {
    let questionIds: string[] = [];

    if (this.kind != "formula") {
      return questionIds;
    }

    const metadata = this.question.metadata as string;

    const chunks = metadata.split(/(@q_[0-9]+)/);
    for (const chunk of chunks) {
      if (chunk.includes("@q_")) {
        const questionId = chunk.replace("@q_", "");
        questionIds.push(questionId);
      }
    }

    return questionIds;
  }
}

//
import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import * as Integrations from '@sentry/integrations';

Sentry.init({
   dsn: "https://5e2d6cbd88d3443aa0716824dc475a47@sentry.monade.io/28",
   blacklistUrls: [
     // Facebook flakiness
     /graph\.facebook\.com/i,
     // Facebook blocked
     /connect\.facebook\.net\/en_US\/all\.js/i,
     // Chrome extensions
     /extensions\//i,
     /^chrome:\/\//i
   ],
   integrations: [
     new Integrations.Vue({
       Vue,
       attachProps: true,
     }),
   ],
 });
 //

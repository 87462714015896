import { JSONData, JSONModel } from "./interfaces";

export class Parser {
  static parse(
    data: JSONData | JSONModel[] | JSONModel,
    included: JSONModel[] = []
  ): Model | Model[] | null {
    if (!data) {
      return null;
    }
    if (Array.isArray(data)) {
      return Parser.parseList(data, included);
    } else if ("data" in data && !("id" in data)) {
      return Parser.parse(data.data, data.included || included);
    } else {
      return Parser.parseElement(data, included);
    }
  }

  static parseList(list: JSONModel[], included: JSONModel[]) {
    return list.map((e) => {
      return Parser.parseElement(e, included);
    });
  }

  static parseElement(element: JSONModel, included: JSONModel[]): Model {
    const models = require(".");

    element = Parser.load(element, included);
    const model = Object.values(models).find(
      (e: any) => e.type === element.type
    ) as any;
    return new (model || Model)(element, included);
  }

  static load(element: JSONModel, included: JSONModel[]) {
    return (
      included.find((e) => e.id == element.id && e.type === element.type) ||
      element
    );
  }
}

export default class Model {
  id: string;
  static type: string | undefined;

  constructor(data?: JSONModel | null, included: JSONModel[] = []) {
    if (!data) {
      return;
    }
    this.id = data.id;
    for (const key in data.attributes) {
      (this as any)[key] = data.attributes[key];
    }

    for (const key in data.relationships) {
      const relation = data.relationships[key];
      if (relation) {
        (this as any)[key] = Parser.parse(relation, included);
      }
    }
  }

  toJSON(): any {
    let result = { ...this };
    return result;
  }

  toFormData() {
    const data = this.toJSON();
    const formData = new FormData();
    for (let key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        if (Array.isArray(data[key])) {
          for (const value of data[key]) {
            formData.append(key + "[]", value);
          }
        } else if (data[key] instanceof File) {
          formData.append(key, data[key], data[key].filename);
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    return formData;
  }
}

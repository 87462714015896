const routes = [
  {
    path: "databases",
    component: () =>
      import(/* webpackChunkName: "admin" */ "views/Database/TableList.vue"),
    name: "database_list",
    meta: { role: ["admin", "superadmin"] },
  },
  {
    path: "databases/:id/edit",
    component: () =>
      import(/* webpackChunkName: "admin" */ "views/Database/Edit.vue"),
    name: "database_edit",
    meta: { role: ["admin", "superadmin"] },
    children: [
      {
        path: ":groupId",
        component: () =>
          import(/* webpackChunkName: "admin" */ "views/Group/Edit.vue"),
        name: "group_edit",
        meta: { role: ["admin", "superadmin"] },
      },
    ],
  },
  {
    path: "subscription_requests",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "views/SubscriptionRequest/TableList.vue"
      ),
    name: "request_list",
    meta: { role: ["admin", "superadmin"] },
  },
  {
    path: "databases/:id",
    component: () =>
      import(/* webpackChunkName: "admin" */ "views/Database/Show.vue"),
    name: "database_show",
    meta: { role: ["admin", "superadmin"] },
  },
];

export default routes;

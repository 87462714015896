const routes = [
  {
    path: "/styleguide",
    component: () =>
      import(/* webpackChunkName: "styleguide" */ "views/Styleguide/Index.vue"),
    meta: { role: ["anyone"] },
    children: [
      {
        path: "",
        name: "styleguide",
        component: () =>
          import(
            /* webpackChunkName: "styleguide" */ "views/Styleguide/Typography.vue"
          ),
      },
      {
        path: "buttons",
        name: "styleguide-buttons",
        component: () =>
          import(
            /* webpackChunkName: "styleguide" */ "views/Styleguide/Buttons.vue"
          ),
      },
      {
        path: "labels",
        name: "styleguide-labels",
        component: () =>
          import(
            /* webpackChunkName: "styleguide" */ "views/Styleguide/Labels.vue"
          ),
      },
      {
        path: "tables",
        name: "styleguide-tables",
        component: () =>
          import(
            /* webpackChunkName: "styleguide" */ "views/Styleguide/Tables.vue"
          ),
      },
      {
        path: "form",
        name: "styleguide-form",
        component: () =>
          import(
            /* webpackChunkName: "styleguide" */ "views/Styleguide/Form.vue"
          ),
      },
    ],
  },
];

export default routes;

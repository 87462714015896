import User from "../models/User";
import axios from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
const Routes = require("../utils/RailsRoutes");

@Module({
  namespaced: false,
  name: "auth",
})
export default class Auth extends VuexModule {
  _token: string | undefined = undefined;

  get accessToken() {
    return this._token;
  }

  get loginTag() {
    return "login";
  }

  @Mutation
  authSuccess(token: string) {
    this._token = token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  @Mutation
  clearAuth() {
    this._token = undefined;
    delete axios.defaults.headers.common["Authorization"];
  }

  @Action({ rawError: true })
  login(user: { email: string; password: string }): Promise<User | undefined> {
    const { commit, dispatch, getters } = this.context;
    commit("setCurrentUser", undefined);
    commit("clearAuth");

    return dispatch("http/request", {
      tag: getters.loginTag,
      url: Routes.user_session_path({ format: "json" }),
      data: { user },
      method: "POST",
      raw: true,
    }).then((resp) => {
      const [_, token] = (resp.headers.authorization || " ").split(" ");
      return dispatch("authenticate", token);
    });
  }

  @Action
  logout() {
    const { commit } = this.context;
    commit("setCurrentUser", undefined);
    commit("clearAuth");
  }

  @Action({ rawError: true })
  async authenticate(token?: string): Promise<User> {
    const { commit, dispatch, rootGetters, getters } = this.context;

    token = token || getters.accessToken;

    if (!token) {
      throw new Error("Missing token");
    }

    if (rootGetters.currentUser instanceof User) {
      return rootGetters.currentUser;
    }
    try {
      const user = await dispatch("loadCurrentUser", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!user) {
        throw new Error("No such user");
      }
      commit("authSuccess", token);
      return user;
    } catch (e) {
      commit("clearAuth");
      throw e;
    }
  }
}

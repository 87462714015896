






import { Vue, Component, Prop } from "vue-property-decorator";
import { currentUserStore } from "store/typed";
import { User } from "models";

@Component({})
export default class App extends Vue {
  get currentUser(): User | undefined {
    return currentUserStore.currentUser;
  }
}

import { User } from ".";
import Group from "./Group";
import Model from "./Model";

export interface DatabaseUpdatePayload {
  id: string;
  active?: string;
  name?: string;
  description?: string;
}

export default class Database extends Model {
  static type = "databases";
  static showIncludes = "groups.questions,*";
  static counters = { databases: "doctorsCount,questionsCount,groupsCount" };

  name: string;
  description: string;
  active: boolean;
  createdAt: Date;
  surveysCount: number;
  doctorsCount: number;
  groupsCount: number;
  questionsCount: number;
  groups: Group[];
  user: User;
  doctors: User[];

  static init() {
    const database = new Database();
    database.name = "Database senza titolo";
    return database;
  }
}

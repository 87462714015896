import store from "store";
import Http from "store/http";
import CurrentUser from "store/currentUser";
import Auth from "./auth";
import DatabaseStore from "./database";
import SurveyStore from "./survey";
import { getModule } from "vuex-module-decorators";

export const httpStore: Http = getModule(Http, store);
export const currentUserStore: CurrentUser = getModule(CurrentUser, store);
export const authStore: Auth = getModule(Auth, store);
export const databaseStore: DatabaseStore = getModule(DatabaseStore, store);
export const surveyStore: SurveyStore = getModule(SurveyStore, store);

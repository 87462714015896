import { Component, Vue } from "vue-property-decorator";

@Component
export default class UsersMixin extends Vue {
  getUsers(data: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_users", { format: "json", ...data }),
      tag
    );
  }

  getUser(id: number, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_users", { format: "json", id }),
      tag
    );
  }

  destroyUser(id: number, tag: string) {
    return this.$httpDelete(this.$urlFor("v1_admin_user", id), tag);
  }

  createUser(data: any, tag: string) {
    return this.$httpPost(this.$urlFor("v1_admin_users"), tag, data);
  }

  updateUser(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor("v1_admin_user", { id: data.id }),
      tag,
      data
    );
  }
}

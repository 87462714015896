import { User, Database } from ".";
import Model from "./Model";

export default class DatabaseUser extends Model {
  static type = "databaseUsers";

  user: User;
  database: Database;

  get fullname() {
    return this.user.firstName + " " + this.user.lastName;
  }
}

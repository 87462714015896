import { Component, Mixins } from "vue-property-decorator";
import { AdminDatabasesMixin } from "mixins/http";
import RoutingMixin from "mixins/Routing";
import RequestsMixin from "mixins/http/RequestsMixin";

@Component
class AdminDatabaseService extends Mixins(
  AdminDatabasesMixin,
  RequestsMixin,
  RoutingMixin
) {}

export default new AdminDatabaseService();

import {
  Survey,
  Answer,
  AnswersGroup,
  Group,
  GroupWithAnswersGroup,
} from "models";
import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { httpStore } from "store/typed";
import Vue from "vue";

import surveyService from "service/userSurveyService";
import answersGroupService from "service/userAnswersGroupService";
import answerService from "service/userAnswerService";
import { AnswerPayload } from "models/Answer";

export const SURVEY_TAG = "survey_change";
export const SURVEY_RELOADING_TAG = "survey_reload";

@Module({
  namespaced: true,
  name: "survey",
})
export default class SurveyStore extends VuexModule {
  private _current: Survey | undefined = undefined;
  private _currentGroup: AnswersGroup | undefined = undefined;

  get current() {
    return this._current;
  }

  get currentGroup() {
    return this._currentGroup;
  }

  get groupsWithAnswersGroup() {
    if (!this.current) {
      return [];
    }
    return this.current.database.groups.map((group) => {
      return new GroupWithAnswersGroup(
        group,
        this.current!.answersGroups.find((g) => g.group.id == group.id)
      );
    });
  }

  get groupIndex() {
    return (
      this._current?.database?.groups?.findIndex(
        (g) => g.id === this._currentGroup?.group?.id
      ) || 0
    );
  }

  get loading() {
    return httpStore.status[SURVEY_TAG]?.loading;
  }

  get reloading() {
    return httpStore.status[SURVEY_RELOADING_TAG]?.loading;
  }

  @Mutation
  setCurrent(payload: Survey | undefined) {
    this._current = payload;
    this._currentGroup =
      this._current?.lastAnswersGroup ?? this._current?.answersGroups?.[0];
  }
  @Mutation
  updateCurrent(payload: Partial<Survey>) {
    this._current = Object.assign(this._current, payload);
  }

  @Mutation
  setCurrentGroup(id?: string) {
    if (id) {
      this._currentGroup = this._current?.answersGroups?.find(
        (a) => a.id == id
      );
    } else {
      this._currentGroup = this._current?.answersGroups?.[0];
    }
  }

  @Mutation
  addAnswerGroup(payload: AnswersGroup) {
    this._current?.answersGroups?.push(payload);
  }

  @Mutation
  updateAnswerOnAnswerGroup({
    answer,
    forceReassign,
  }: {
    answer: Answer | undefined;
    forceReassign: boolean;
  }) {
    if (answer && this._currentGroup) {
      const index = this._currentGroup.answers.findIndex(
        (a) => a.id === answer.id
      );
      if (index > -1) {
        if (forceReassign) {
          Vue.set(this._currentGroup.answers, index, answer);
        }
      } else {
        this._currentGroup.answers.push(answer);
      }
    }
  }

  @Mutation
  syncCurrentAnswersGroup(payload: Partial<AnswersGroup>) {
    Object.assign(this._currentGroup, payload);
  }

  // loads survey and all the related stuff (Database etc.)
  @Action({ rawError: true })
  async loadSurvey(id: string) {
    const { commit } = this.context;
    const resp = await surveyService.getSurvey(id, SURVEY_TAG);
    commit("setCurrent", resp);
  }

  @Action({ rawError: true })
  async clearSurvey() {
    const { commit } = this.context;
    commit("setCurrent", undefined);
  }

  @Action({ rawError: true })
  async updateSurvey(survey: Partial<Survey>) {
    if (!this.current) {
      console.warn("current is null");
      return null;
    }
    const { commit } = this.context;
    const resp = await surveyService.updateSurvey(
      { ...survey, id: this.current.id },
      SURVEY_TAG
    );
    commit("setCurrent", resp);
  }

  @Action({ rawError: true })
  async updateSurveyBookmark(survey: Partial<Survey>) {
    if (!this.current) {
      console.warn("current is null");
      return null;
    }
    const { commit } = this.context;
    const resp = await surveyService.updateBookmark(
      { ...survey, id: this.current.id },
      SURVEY_TAG
    );
    commit("updateCurrent", { bookmarkId: resp.bookmarkId });
  }

  @Action({ rawError: true })
  async destroySurvey() {
    if (!this.current) {
      console.warn("current is null");
      return null;
    }
    const { commit } = this.context;
    const resp = await surveyService.destroySurvey(this.current.id, SURVEY_TAG);
    commit("setCurrent", undefined);
  }

  @Action({ rawError: true })
  async addAnswersGroup(group: Group) {
    if (!this.current) {
      console.warn("current is null");
      return null;
    }
    const { commit } = this.context;

    const answerGroup = await answersGroupService.createAnswersGroup(
      this.current.id,
      { groupId: group.id },
      SURVEY_TAG
    );
    commit("addAnswerGroup", answerGroup);
    return answerGroup;
  }

  @Action({ rawError: true })
  async reloadAnswersGroup() {
    const { commit } = this.context;
    if (!this.currentGroup) {
      return;
    }
    const answersGroup = await answersGroupService.getAnswersGroup(
      this.currentGroup.id,
      SURVEY_RELOADING_TAG,
      {
        include: "answers.question",
        fields: { answersGroup: "status,answers" },
      }
    );

    commit("syncCurrentAnswersGroup", answersGroup);
  }

  @Action({ rawError: true })
  async syncAnswersGroup() {
    const { commit } = this.context;
    if (!this.currentGroup) {
      return;
    }
    const answersGroup = await answersGroupService.getAnswersGroup(
      this.currentGroup.id,
      SURVEY_TAG,
      { include: "", fields: { answersGroup: "status" } }
    );

    commit("syncCurrentAnswersGroup", answersGroup);
  }

  // new answer, then reloads the group in order to have all counters updated
  @Action({ rawError: true })
  async addAnswer(payload: Answer) {
    if (!this.currentGroup) {
      return;
    }
    const { commit, dispatch } = this.context;
    await answerService.createAnswer(payload, SURVEY_TAG);
    const group = await answersGroupService.getAnswersGroup(
      this.currentGroup.id,
      SURVEY_TAG
    );
    commit("setCurrentGroup", group.id);
    await dispatch("syncAnswersGroup");
  }

  @Action({ rawError: true })
  async getAnswer(id: string) {
    const { commit, dispatch } = this.context;
    const resp = await answerService.getAnswer(id, SURVEY_TAG);
    commit("updateAnswerOnAnswerGroup", { answer: resp, forceReassign: true });
    return resp;
  }

  @Action({ rawError: true })
  async updateAnswer(payload: AnswerPayload) {
    const { commit, dispatch } = this.context;

    if (payload.id) {
      const resp = await answerService.updateAnswer(payload, SURVEY_TAG);
      commit("updateAnswerOnAnswerGroup", { answer: resp });
    } else {
      const resp = await answerService.createAnswer(payload, SURVEY_TAG);
      commit("updateAnswerOnAnswerGroup", { answer: resp });
    }
    await dispatch("syncAnswersGroup");
  }
}

import { Group } from "models";
import Model from "./Model";

export type QuestionKinds =
  | "text"
  | "range"
  | "single_choice"
  | "multiple_choice"
  | "multiple_choice_with_notes"
  | "number"
  | "boolean"
  | "date"
  | "formula"
  | "multi_levels_single_choice"
  | "multi_levels_multiple_choice";

export type QuestionRange = { min: number; max: number };
export type QuestionOptions = { [key: string]: string };

export type MultiLevelsSingleChoiceQuestionOptions =
  | {
      [id: string]: {
        value: string;
        details: MultiLevelsSingleChoiceQuestionOptions;
      };
    }
  | {};

export type MultiLevelsMultipleChoiceQuestionOptions =
  MultiLevelsSingleChoiceQuestionOptions[];

export type MultiLevelsSingleChoiceDefaults = {
  key?: string;
  value?: string;
  details?: MultiLevelsSingleChoiceDefaults;
};

export type MultiLevelsMultipleChoiceDefaults =
  MultiLevelsSingleChoiceDefaults[];

export class QuestionUpdatePayload {
  id: string;
  name?: string;
  description?: string;
  kind?: QuestionKinds;
  position?: number;
  metadata?: any;
  defaults?:
    | { key: string; value: string }[]
    | MultiLevelsSingleChoiceDefaults[];

  createMetadata(kind: string) {
    if (kind === "range") {
      this.metadata = { min: 0, max: 100 };
    } else if (
      kind === "single_choice" ||
      kind === "multiple_choice" ||
      kind === "multiple_choice_with_notes"
    ) {
      this.metadata = {};
    } else if (kind === "formula") {
      this.metadata = "";
    } else if (kind === "multi_levels_single_choice") {
      this.metadata = {};
    } else if (kind === "multi_levels_multiple_choice") {
      this.metadata = {};
    }
  }
  createDefaults(kind: string) {
    if (
      kind === "single_choice" ||
      kind === "multiple_choice" ||
      kind === "multiple_choice_with_notes" ||
      kind === "multi_levels_single_choice" ||
      kind === "multi_levels_multiple_choice"
    ) {
      this.defaults = [];
    }
  }
}

export default class Question extends Model {
  static kinds: { id: string; label: string }[] = [
    { id: "text", label: "Testo libero" },
    { id: "date", label: "Data" },
    { id: "single_choice", label: "Scelta singola" },
    { id: "multiple_choice", label: "Scelta multipla" },
    { id: "multiple_choice_with_notes", label: "Scelta multipla con note" },
    { id: "range", label: "Range numerico" },
    { id: "number", label: "Numero" },
    { id: "boolean", label: "Sì/No" },
    { id: "formula", label: "Formula" },
    { id: "multi_levels_single_choice", label: "Scelta singola multi livello" },
    {
      id: "multi_levels_multiple_choice",
      label: "Scelta multipla multi livello",
    },
  ];

  static type = "questions";

  name: string;
  description: string;
  kind: QuestionKinds;
  required: boolean;
  createdAt: Date;
  position: number;
  group: Group;
  metadata:
    | QuestionRange
    | null
    | QuestionOptions
    | String
    | MultiLevelsSingleChoiceQuestionOptions;
  defaults:
    | { key: string; value: string }[]
    | MultiLevelsSingleChoiceDefaults[]
    | MultiLevelsMultipleChoiceDefaults;

  static init(group: Group) {
    const question = new Question();
    question.group = group;
    question.name = "Domanda senza titolo";
    question.kind = "text";
    return question;
  }

  get isSingleChoice() {
    return this.kind === "single_choice";
  }

  get isMultiLevelSingleChoice() {
    return this.kind === "multi_levels_single_choice";
  }

  get isMultiLevelMultipleChoice() {
    return this.kind === "multi_levels_multiple_choice";
  }

  get isMultipleChoice() {
    return this.kind === "multiple_choice";
  }

  get isMultipleChoiceWithNotes() {
    return this.kind === "multiple_choice_with_notes";
  }

  get isText() {
    return this.kind === "text";
  }

  get isFormula() {
    return this.kind === "formula";
  }

  get isRange() {
    return this.kind === "range";
  }
}

export function groupColors() {
  return [
    "#EF3332",
    "#F02E78",
    "#AA08B7",
    "#6F33BE",
    "#3C4FBC",
    "#0097FB",
    "#00ABFC",
    "#00BFD9",
    "#009A88",
    "#00B441",
    "#7AC52E",
    "#C9DE00",
    "#FFEC00",
    "#FFBF00",
    "#FF9200",
    "#FF652C",
    "#7F5344",
    "#9E9E9E",
    "#587E8D",
    "#121212",
  ];
}

const routes = [
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "auth" */ "views/Login.vue"),
    name: "login",
    meta: { noAuth: true },
  },
  {
    path: "/confirmation",
    component: () =>
      import(/* webpackChunkName: "auth" */ "views/Password/Confirmation.vue"),
    name: "confirmation",
    meta: { noAuth: true },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "auth" */ "views/Logout.vue"),
    meta: { role: ["anyone"] },
  },
  {
    path: "/forgot_password",
    component: () =>
      import(/* webpackChunkName: "auth" */ "views/Password/Forgot.vue"),
    name: "forgot_password",
    meta: { noAuth: true },
  },
  {
    path: "/reset_password",
    component: () =>
      import(/* webpackChunkName: "auth" */ "views/Password/Reset.vue"),
    name: "reset_password",
    meta: { noAuth: true },
  },
  {
    path: "/set_password",
    component: () =>
      import(/* webpackChunkName: "auth" */ "views/Password/Set.vue"),
    name: "set_password",
    meta: { noAuth: true },
  },
];

export default routes;

export default {
  methods: {
    $isRouteActive(route: string) {
      return !!this.$route.matched.find((e: any) => e.name === route);
    },
    $areRouteActive(routes: string[]) {
      return !!this.$route.matched.some((e: any) => routes.includes(e.name));
    },
  },
};

import { Answer, Database, Group, Question, User } from ".";
import Model from "./Model";
import Survey from "./Survey";

export default class AnswersGroup extends Model {
  static type = "groupSurveys";

  status: string;
  survey: Survey;
  group: Group;
  requiredAnswersCount: number;
  answers: Answer[];
}

import Model from "./Model";

export default class User extends Model {
  static type = "users";

  static adminRoles: Array<{ name: string; id: string }> = [
    { name: "SuperAdmin", id: "superadmin" },
    { name: "Admin", id: "admin" },
  ];

  static doctorRoles: Array<{ name: string; id: string }> = [
    { name: "Dottore", id: "doctor" },
    { name: "Supervisore", id: "supervisor" },
  ];

  static roles: Array<{ name: string; id: string }> = [
    ...User.adminRoles,
    ...User.doctorRoles,
  ];

  email!: string;
  firstName!: string;
  lastName!: string;
  role!: string;
  active!: boolean;
  confirmedAt?: Date;
  request: Request;

  fullname(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static getRoleLabel(role: string): string | undefined {
    return this.roles.find((o) => o.id === role)?.name;
  }

  isOneOf(roles: string[]) {
    return roles.includes(this.role);
  }

  get isAdmin() {
    return this.role === "admin";
  }

  get isSuperAdmin() {
    return this.role === "superadmin";
  }

  get isDoctor() {
    return this.role === "doctor";
  }
}

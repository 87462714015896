import { Component, Mixins } from "vue-property-decorator";

import RoutingMixin from "mixins/Routing";
import RequestsMixin from "mixins/http/RequestsMixin";
import { AnswersMixin } from "mixins/http";

@Component({})
class UserAnswerService extends Mixins(
  RoutingMixin,
  RequestsMixin,
  AnswersMixin
) {}

export default new UserAnswerService();

import VueRouter, { RouteConfig } from "vue-router";
import StyleguideRoutes from "router/Styleguide";
import Authentication from "router/Authentication";
import RegistrationRoutes from "router/Registration";
import AdminRoutes from "router/Admin";
import SurveysRoutes from "router/Doctor/Surveys";

import { User } from "models";
import { authStore } from "store/typed";

const routes: RouteConfig[] = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: 'commons' */ "views/Home.vue"),
    name: "root",
    meta: { role: ["anyone"] },
  },
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/Container.vue"),
    meta: { role: ["admin", "superadmin"] },
    children: [
      {
        path: "/",
        redirect: "databases",
        name: "secure_root",
        meta: { role: ["superadmin", "admin"] },
      },
      ...AdminRoutes,
    ],
  },
  {
    path: "/doctor",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/Container.vue"),
    meta: { role: ["doctor"] },
    children: [
      {
        path: "",
        name: "doctor_dashboard",
        component: () =>
          import(/* webpackChunkName: 'doctor' */ "views/Doctor/Dashboard.vue"),
        meta: { role: ["doctor"] },
      },
      ...SurveysRoutes,
    ],
  },
  ...RegistrationRoutes,
  ...StyleguideRoutes,
  ...Authentication,

  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/NotFound.vue"),
    name: "404",
    meta: { role: ["anyone"] },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/NotFound.vue"),
    name: "fallback_404",
    meta: { role: ["anyone"] },
  },
];

const router = new VueRouter({
  base: "/",
  routes,
  mode: "history",
});

router.beforeEach(async (to, _from, next) => {
  const { role } = to
    ? to.matched?.find((e) => e.meta)?.meta || { role: [] }
    : { role: [] };

  try {
    const user = await authStore.authenticate();
    if (to?.matched?.some((record) => record.meta.noAuth)) {
      next("/");
      return;
    }

    if (role?.includes(user?.role) || role?.includes("anyone")) {
      // if authorized
      next();
      return;
    } else {
      next(false);
      return;
    }
  } catch (e) {
    if (
      to?.matched?.every(
        (record) => record.meta.noAuth || role?.includes("anyone")
      )
    ) {
      next();
      return;
    } else {
      next(`/login?redirect=${encodeURIComponent(window.location.toString())}`);
    }
  }
});

export default router;

import { createDecorator } from "vue-class-component";
import Vue from "vue";
import store from "store";

export default (tag: string) => {
  return createDecorator((options, key) => {
    const beforeCreate = function (this: Vue) {
      this.$store.dispatch("http/dispatchRegister", tag);
    };

    if (options.beforeCreate) {
      const oldBeforeCreate = options.beforeCreate;
      options.beforeCreate = function () {
        oldBeforeCreate.bind(this)();
        beforeCreate.bind(this)();
      };
    } else {
      options.beforeCreate = beforeCreate;
    }
    options.computed = options.computed || {};
    options.computed[key] = function mappedGetter() {
      return store.getters["http/status"][tag];
    };
  });
};

import { Component, Vue } from "vue-property-decorator";

const DEFAULT_INCLUDE = "*,answers.question";

@Component
export default class AnswersGroupsMixin extends Vue {
  getAnswersGroups(data: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_survey_answers_groups", { format: "json", ...data }),
      tag
    );
  }

  getAnswersGroup(id: number | string, tag: string, data: any = {}) {
    return this.$httpGet(
      this.$urlFor("v1_answers_group", id, { format: "json", ...data }),
      tag
    );
  }

  destroyAnswersGroup(id: number | string, tag: string) {
    return this.$httpDelete(this.$urlFor("v1_admin_answers_group", id), tag);
  }

  createAnswersGroup(surveyId: string, data: any, tag: string) {
    return this.$httpPost(
      this.$urlFor("v1_survey_answers_groups", surveyId, {
        include: DEFAULT_INCLUDE,
      }),
      tag,
      data
    );
  }

  updateAnswersGroup(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor("v1_answers_group", data.id, { include: DEFAULT_INCLUDE }),
      tag,
      data
    );
  }
}

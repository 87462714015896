import { JSONData, JSONModel } from "models/interfaces";
import { Parser } from "models/Model";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class ModelParserMixin extends Vue {
  $parseModel(list: JSONData | JSONModel[] | JSONModel) {
    return Parser.parse(list);
  }
}

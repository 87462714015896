






import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import RequestData from "store/types/RequestData";
import { httpStore } from "store/typed";

@Component
export default class DisplayErrors extends Vue {
  @Prop({ required: true }) readonly tag!: string;
  @Prop({ default: () => [422] }) readonly statusCodes!: number[];

  get status() {
    return httpStore.status;
  }

  get errors() {
    return (
      this.statusCodes.indexOf(this.status[this.tag]?.statusCode) !== -1 &&
      this.status[this.tag]?.errors
    );
  }
}

import RegisterHttp from "decorators/RegisterHttp";
import RequestData from "store/types/RequestData";
import { Component, Mixins, Vue } from "vue-property-decorator";
import { httpStore } from "store/typed";
import { showToast, showErrorToast } from "utils/Toast";

export const getDatabasesTag = "get_databases";
export const updateDatabaseTag = "update_database";
export const createDatabaseTag = "create_database";
export const destroyDatabaseTag = "destroy_database";

@Component
export default class AdminDatabasesMixin extends Vue {
  getDatabases(data: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_databases", { format: "json", ...data }),
      tag
    );
  }

  getDatabase(id: number | string, include: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_database", {
        format: "json",
        id,
      }) +
        "?include=" +
        include,
      tag
    );
  }

  getCounters(id: number | string, fields: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_database", {
        format: "json",
        id,
        ...fields,
      }),
      tag
    );
  }

  async destroyDatabase(id: number | string, tag: string) {
    try {
      return await this.$httpDelete(this.$urlFor("v1_admin_database", id), tag);
    } catch (e) {
      showErrorToast(e.response.data.message);
      return undefined;
    }
  }

  createDatabase(data: any, tag: string) {
    return this.$httpPost(this.$urlFor("v1_admin_databases"), tag, data);
  }

  updateDatabase(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor("v1_admin_database", { id: data.id }),
      tag,
      data
    );
  }
}

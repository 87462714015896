import Vue from "vue";
import VueMoment from "vue-moment";
import * as moment from "moment";
import "moment/locale/it";

moment.locale("it");

Vue.use(VueMoment, {
  moment,
});

import { Component, Vue } from "vue-property-decorator";

@Component
export default class PasswordsMixin extends Vue {
  forgotPassword(data: any, tag: string) {
    return this.$httpPost(
      this.$urlFor("passwords_forgot", { format: "json" }),
      tag,
      data
    );
  }
  resetPassword(data: any, tag: string) {
    return this.$httpPost(
      this.$urlFor("passwords_reset", { format: "json" }),
      tag,
      data
    );
  }
}

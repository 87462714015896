import jsFileDownloader from "js-file-downloader";
import { Survey } from "models";
import { authStore } from "store/typed";
import { Component, Vue } from "vue-property-decorator";

export const getUserSurveysTag = "get_user_surveys";
const DEFAULT_INCLUDE =
  "database.groups.questions,answers_groups.answers.question,answers_groups.group,user";

@Component
export default class SurveysMixin extends Vue {
  getSurveys(data: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_database_surveys", data.database_id, {
        format: "json",
        ...data,
      }),
      tag
    );
  }

  async dumpSurveys(id: string) {
    const url = this.$urlFor("dump_v1_database_surveys", id, {
      format: "xlsx",
    });

    await new jsFileDownloader({
      url: url,
      headers: [
        { name: "Authorization", value: `Bearer ${authStore.accessToken}` },
      ],
    });
  }

  getSurvey(id: number | string, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_survey", id, {
        format: "json",
        include: DEFAULT_INCLUDE,
      }),
      tag
    );
  }

  destroySurvey(id: number | string, tag: string) {
    return this.$httpDelete(this.$urlFor("v1_survey", id), tag);
  }

  createSurvey(data: Partial<Survey> & { database_id: string }, tag: string) {
    return this.$httpPost(
      this.$urlFor("v1_database_surveys", data.database_id),
      tag,
      data
    );
  }

  updateSurvey(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor("v1_survey", data.id, { include: DEFAULT_INCLUDE }),
      tag,
      data
    );
  }

  updateBookmark(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor("v1_survey_update_bookmark", data.id),
      tag,
      data
    );
  }
}

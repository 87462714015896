import { Answer } from "models";
import { AnswerPayload } from "models/Answer";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AnswersMixin extends Vue {
  getAnswers(data: any, tag: string): Promise<Answer[]> {
    return this.$httpGet(
      this.$urlFor("v1_answers_group_answers", { format: "json", ...data }),
      tag
    );
  }

  getAnswer(id: number | string, tag: string): Promise<Answer> {
    return this.$httpGet(
      this.$urlFor("v1_answer", { format: "json", id }),
      tag
    );
  }

  destroyAnswer(id: number | string, tag: string): Promise<Answer> {
    return this.$httpDelete(this.$urlFor("v1_answer", id), tag);
  }

  createAnswer(data: AnswerPayload, tag: string): Promise<Answer> {
    return this.$httpPost(
      this.$urlFor("v1_answers_group_answers", data.answersGroupId),
      tag,
      data
    );
  }

  updateAnswer(data: AnswerPayload, tag: string): Promise<Answer> {
    return this.$httpPut(this.$urlFor("v1_answer", data.id), tag, data);
  }
}

import { User } from ".";
import Database from "./Database";
import AnswersGroup from "./AnswersGroup";
import Model from "./Model";
import Question from "./Question";
import { groupColors } from "utils/ColorHelper";

export interface GroupUpdatePayload {
  id: string;
  position?: string;
  color?: string;
  description?: string;
  name?: string;
}

export default class Group extends Model {
  static type = "groups";

  name: string;
  description: string | undefined;
  color: string;
  createdAt: Date;
  position: number;
  answersCount: number;
  requiredAnswersCount: number;
  database: Database;
  questions: Question[];
  answersGroup: AnswersGroup | undefined;

  static init(database: Database): Group {
    const group = new Group();
    group.name = "Gruppo senza titolo";

    // TODO : aggiungere lista colori
    group.color = groupColors()[database.groups.length % groupColors().length];
    group.database = database;
    return group;
  }
}

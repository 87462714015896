/* eslint no-console: 0 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "../App.vue";
import "../styles/app.scss";
import Toasted from "vue-toasted";
import VueRouter from "vue-router";
import RoutingMixin from "mixins/Routing";
import ModelParserMixin from "mixins/ModelParser";
import RouteHelpersMixin from "mixins/RouteHelpers";
import RequestsMixin from "mixins/http/RequestsMixin";
import DisplayErrors from "../components/DisplayErrors.vue";
import VIcon from "../components/VIcon.vue";
import "../initializers";

import router from "router";
import store from "store";

declare module "vue/types/vue" {
  interface Vue {
    $urlFor(name: string, ...args: any[]): string;
    $httpGet(url: string, tag: string): Promise<any>;
    $httpPost(url: string, tag: string, data: any): Promise<any>;
    $httpPut(url: string, tag: string, data: any): Promise<any>;
    $httpDelete(url: string, tag: string): Promise<any>;
    $moment: any;
    $mq: any;
  }
}

Vue.use(VueRouter);
window.addEventListener("load", () => {
  Vue.use(Toasted);
});
Vue.component("v-icon", VIcon);
Vue.component("display-errors", DisplayErrors);
Vue.mixin(RoutingMixin);
Vue.mixin(ModelParserMixin);
Vue.mixin(RouteHelpersMixin);
Vue.mixin(RequestsMixin);

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Vue.component('fa-icon', FontAwesomeIcon);

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount();
  document.body.appendChild(app.$el);
});

import { Component, Vue } from "vue-property-decorator";

@Component
export default class SubscriptionRequestsMixin extends Vue {
  createSubscriptionRequest(data: any, tag: string) {
    return this.$httpPost(this.$urlFor("v1_subscription_requests"), tag, data);
  }

  validateSubscriptionRequest(id: string, tag: string) {
    return this.$httpPut(
      this.$urlFor("validate_email_v1_subscription_request", id),
      tag,
      {}
    );
  }

  acceptSubscriptionRequest(id: string, tag: string) {
    return this.$httpPut(
      this.$urlFor("accept_v1_admin_subscription_request", id),
      tag,
      {}
    );
  }

  acceptWithPasswordSubscriptionRequest(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor(
        "accept_with_password_v1_admin_subscription_request",
        data.id
      ),
      tag,
      data
    );
  }

  rejectSubscriptionRequest(id: string, tag: string) {
    return this.$httpPut(
      this.$urlFor("reject_v1_admin_subscription_request", id),
      tag,
      {}
    );
  }

  destroySubscriptionRequest(id: number | string, tag: string) {
    return this.$httpDelete(
      this.$urlFor("v1_admin_subscription_request", id),
      tag
    );
  }

  getSubscriptionRequests(data: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_subscription_requests", {
        format: "json",
        ...data,
      }),
      tag
    );
  }
}

import Database, { DatabaseUpdatePayload } from "../models/Database";
import Group, { GroupUpdatePayload } from "../models/Group";

import { Method } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { httpStore } from "store/typed";
import { Question } from "models";
import databaseService from "service/adminDatabaseService";
import groupService from "service/adminGroupService";
import questionService from "service/adminQuestionService";

import Vue from "vue";
import { QuestionUpdatePayload } from "models/Question";

export const DATABASE_TAG = "database_change";
export const DATABASE_COUNTER_TAG = "database_counter_change";

@Module({
  namespaced: true,
  name: "database",
})
export default class DatabaseStore extends VuexModule {
  private _current: Database | undefined = undefined;
  private _currentGroup: Group | undefined = undefined;

  get current() {
    return this._current;
  }

  get currentGroup() {
    return this._currentGroup;
  }

  get loading() {
    return httpStore.status[DATABASE_TAG]?.loading;
  }

  @Mutation
  setCurrent(payload: Database | undefined) {
    this._current = payload;
    //this._currentGroup = this._current?.groups?.[0];
  }

  @Mutation
  setCurrentGroup(payload: Group | undefined) {
    if (payload) {
      this._currentGroup = payload;
    } else {
      this._currentGroup = this._current?.groups?.[0];
    }
  }

  @Mutation
  setCurrentGroupById(id: string | undefined) {
    if (id) {
      this._currentGroup = this._current?.groups?.find((a) => a.id == id);
    } else {
      this._currentGroup = this._current?.groups?.[0];
    }
  }

  @Mutation
  destroyQuestionFromGroup(payload: Question) {
    if (this._currentGroup) {
      this._currentGroup.questions = this._currentGroup.questions.filter(
        (q) => q.id !== payload.id
      );
    }
  }

  @Mutation
  destroyGroupFromDatabase(payload: Group) {
    if (this._current) {
      let index = -1;
      this._current.groups = this._current.groups.filter((q, i) => {
        if (q.id !== payload.id) {
          return true;
        } else {
          index = i;
          return false;
        }
      });
      if (!this._current.groups.length) {
        this._currentGroup = undefined;
      } else {
        const newIndex = index === 0 ? 0 : index - 1;
        this._currentGroup = this._current.groups[newIndex];
      }
    }
  }

  @Mutation
  addGroupToDatabase(payload: Group) {
    this._current?.groups?.push(payload);
  }

  @Mutation
  addQuestionToGroup(payload: Question) {
    this._currentGroup?.questions?.push(payload);
  }

  @Mutation
  updateQuestionToGroup(payload: Question) {
    if (this._currentGroup) {
      const index = this._currentGroup.questions.findIndex(
        (q) => q.id === payload.id
      );
      if (index > -1) {
        Vue.set(this._currentGroup.questions, index, payload);
      }
    }
  }

  @Mutation
  updateGroupToDatabase(payload: Group) {
    if (this._current) {
      const index = this._current.groups.findIndex((q) => q.id === payload.id);
      if (index > -1) {
        Vue.set(this._current.groups, index, payload);
      }
    }
  }

  @Mutation
  mergeCurrent(payload: Database | undefined) {
    if (this._current) {
      if (payload) {
        this._current = Object.assign(this._current, payload);
      }
    } else {
      this._current = payload;
    }
  }

  @Action({ rawError: true })
  async loadDatabase(id: string) {
    const { commit } = this.context;
    commit("setCurrent", undefined);
    const response = await databaseService.getDatabase(
      id,
      Database.showIncludes,
      DATABASE_TAG
    );

    commit("setCurrent", response);
  }

  @Action({ rawError: true })
  async loadCounters(id: string) {
    const { commit } = this.context;
    const response = await databaseService.getCounters(
      id,
      Database.counters,
      DATABASE_COUNTER_TAG
    );
    if (response) {
      const totalFields = Database.counters.databases.split(",");
      const results: { [key: string]: number } = {};
      totalFields.forEach((field) => {
        results[field] = response[field];
      });
      commit("mergeCurrent", results);
    } else {
      commit("mergeCurrent", undefined);
    }
  }

  @Action({ rawError: true })
  async destroyDatabase(id: string) {
    const { commit } = this.context;
    const response = await databaseService.destroyDatabase(id, DATABASE_TAG);
    commit("setCurrent", undefined);
  }

  @Action({ rawError: true })
  async updateDatabase(payload: DatabaseUpdatePayload) {
    const { commit } = this.context;
    const response = await databaseService.updateDatabase(
      payload,
      DATABASE_TAG
    );

    commit("syncCurrentDatabase", response);
  }

  @Mutation
  syncCurrentDatabase(payload: Partial<Database>) {
    delete payload.groups;
    Object.assign(this._current, payload);
  }

  @Action({ rawError: true })
  async addGroup() {
    const { commit } = this.context;
    const newGroup = Group.init(this.current!);

    const response = await groupService.createGroup(
      {
        database_id: newGroup.database.id,
        name: newGroup.name,
        color: newGroup.color,
      },
      DATABASE_TAG
    );
    commit("setCurrentGroup", response);
    commit("addGroupToDatabase", response);
  }

  @Action({ rawError: true })
  async cloneGroup(groupId: string) {
    const { commit } = this.context;

    const response = await groupService.cloneGroup(
      {
        id: groupId,
      },
      DATABASE_TAG
    );
    commit("setCurrentGroup", response);
    commit("addGroupToDatabase", response);
  }

  @Action({ rawError: true })
  async cloneGroupToDatabase(payload: { groupId: string; databaseId: string }) {
    const { commit } = this.context;
    const response = await groupService.cloneGroupToDatabase(
      {
        id: payload.groupId,
        databaseId: payload.databaseId,
      },
      DATABASE_TAG
    );
    commit("setCurrentGroup", response);
    commit("addGroupToDatabase", response);
  }

  @Action({ rawError: true })
  async updateGroup(payload: GroupUpdatePayload) {
    const { commit } = this.context;
    const response = await groupService.updateGroup(payload, DATABASE_TAG);
    commit("updateGroupToDatabase", response);
  }

  @Action({ rawError: true })
  async destroyGroup(id: string) {
    const { commit } = this.context;
    const response = await groupService.destroyGroup(id, DATABASE_TAG);
    commit("destroyGroupFromDatabase", response);
  }

  @Action({ rawError: true })
  async addQuestion() {
    const { commit } = this.context;

    const newQuestion = Question.init(this.currentGroup!);
    const payload = {
      group_id: newQuestion.group.id,
      name: newQuestion.name,
    };
    const response = await questionService.createQuestion(
      payload,
      DATABASE_TAG
    );
    commit("addQuestionToGroup", response);
  }

  @Action({ rawError: true })
  async updateQuestion(payload: QuestionUpdatePayload) {
    const { commit } = this.context;
    const response = await questionService.updateQuestion(
      payload,
      DATABASE_TAG
    );
    commit("updateQuestionToGroup", response);
  }

  @Action({ rawError: true })
  async getQuestion(id: string) {
    const { commit } = this.context;
    const response = await questionService.getQuestion(id, DATABASE_TAG);
    commit("updateQuestionToGroup", response);
  }

  @Action({ rawError: true })
  async destroyQuestion(id: string) {
    const { commit } = this.context;
    const response = await questionService.destroyQuestion(id, DATABASE_TAG);
    commit("destroyQuestionFromGroup", response);
  }
}

import { AnswersGroup, Group } from "models";

export default class GroupWithAnswersGroup {
  group: Group;
  answersGroup?: AnswersGroup;
  constructor(group: Group, answersGroup?: AnswersGroup) {
    this.group = group;
    this.answersGroup = answersGroup;
  }

  get id() {
    return this.group.id;
  }
}

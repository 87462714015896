import { Component, Mixins } from "vue-property-decorator";

import RoutingMixin from "mixins/Routing";
import RequestsMixin from "mixins/http/RequestsMixin";
import { AnswersGroupsMixin } from "mixins/http";

@Component({})
class UserAnswersGroupService extends Mixins(
  RoutingMixin,
  RequestsMixin,
  AnswersGroupsMixin
) {}

export default new UserAnswersGroupService();

import { Database, Group, User, AnswersGroup } from ".";
import Model from "./Model";

export default class Survey extends Model {
  static type = "surveys";

  user: User;
  status: string;
  createdAt: Date;
  bookmarkId: string;
  notes: string | undefined;
  database: Database;
  lastAnswersGroup: AnswersGroup | undefined;
  code: string;
  answersGroups: AnswersGroup[];

  static init(database: Database, user: User) {
    const item = new Survey();
    item.user = user;
    item.status = "draft";
    item.createdAt = new Date();
    item.database = database;
    return item;
  }

  get bookmarkGroup() {
    return this.answersGroups.find((o) => o.id == this.bookmarkId);
  }
}

import { Component, Vue } from "vue-property-decorator";
import { httpStore } from "store/typed";

@Component
export default class RequestsMixin extends Vue {
  public $httpGet(url: string, tag: string) {
    return httpStore.request({
      tag: tag,
      url: url,
      method: "GET",
    });
  }

  public $httpPost(url: string, tag: string, data: any) {
    return httpStore.request({
      tag: tag,
      url: url,
      data: data,
      method: "POST",
    });
  }

  public $httpPut(url: string, tag: string, data: any) {
    return httpStore.request({
      tag: tag,
      url: url,
      data: data,
      method: "PUT",
    });
  }

  public $httpDelete(url: string, tag: string) {
    return httpStore.request({
      tag: tag,
      url: url,
      method: "DELETE",
    });
  }
}

import User from "../models/User";
import axios from "axios";
import { Parser } from "../models/Model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  stateFactory: true,
  namespaced: false,
  name: "currentUser",
})
export default class CurrentUser extends VuexModule {
  _currentUser: User | undefined = undefined;

  // TODO change in the whole views currentUser with user
  get currentUser() {
    return this._currentUser;
  }

  get user() {
    return this._currentUser;
  }

  @Mutation
  setCurrentUser(payload: User | undefined) {
    this._currentUser = payload;
  }

  @Action
  async loadCurrentUser(options: any = {}) {
    const { commit } = this.context;

    try {
      const response = await axios({ url: "/v1/current_user", ...options });
      const user = Parser.parse(response.data) as User;
      if (user) {
        commit("setCurrentUser", user);
        return user;
      } else {
        commit("setCurrentUser", undefined);
        return undefined;
      }
    } catch (e) {
      commit("setCurrentUser", undefined);
      return undefined;
    }
  }
}

import { Group } from "models";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AdminGroupsMixin extends Vue {
  getGroups(data: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_database_groups", { format: "json", ...data }),
      tag
    );
  }

  getGroup(id: number | string, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_group", { format: "json", id }),
      tag
    );
  }

  destroyGroup(id: number | string, tag: string) {
    return this.$httpDelete(this.$urlFor("v1_admin_group", id), tag);
  }

  cloneGroup(data: any, tag: string) {
    return this.$httpPost(this.$urlFor("clone_v1_admin_group", data.id), tag, {
      id: data.id,
    });
  }

  cloneGroupToDatabase(data: any, tag: string) {
    return this.$httpPost(
      this.$urlFor("clone_to_database_v1_admin_group", data.id),
      tag,
      data
    );
  }

  createGroup(data: any, tag: string) {
    return this.$httpPost(
      this.$urlFor("v1_admin_database_groups", {
        database_id: data.database_id,
      }),
      tag,
      data
    );
  }

  updateGroup(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor("v1_admin_group", { id: data.id }),
      tag,
      data
    );
  }
}

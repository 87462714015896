import { Component, Mixins } from "vue-property-decorator";
import { AdminQuestionsMixin } from "mixins/http";
import RoutingMixin from "mixins/Routing";
import RequestsMixin from "mixins/http/RequestsMixin";

@Component
class AdminQuestionService extends Mixins(
  AdminQuestionsMixin,
  RequestsMixin,
  RoutingMixin
) {}

export default new AdminQuestionService();

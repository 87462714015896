const routes = [
  {
    path: "/subscription_requests",
    component: () =>
      import(/* webpackChunkName: 'commons' */ "views/Container.vue"),
    meta: { noAuth: true },
    children: [
      {
        name: "new_request",
        path: "",
        meta: { noAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'subscription' */ "views/SubscriptionRequest/Create.vue"
          ),
      },
      {
        name: "request_sent",
        path: "sent",
        meta: { noAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'subscription' */ "views/SubscriptionRequest/Confirmation.vue"
          ),
      },
      {
        name: "email_confirmed",
        path: ":code/confirm",
        meta: { noAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'subscription' */ "views/SubscriptionRequest/Confirmed.vue"
          ),
      },
      {
        name: "request_set_password",
        path: "accepted",
        meta: { noAuth: true },
        component: () =>
          import(
            /* webpackChunkName: 'subscription' */ "views/Password/Set.vue"
          ),
      },
    ],
  },
];

export default routes;

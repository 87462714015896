import { Component, Vue } from "vue-property-decorator";

@Component
export default class QuestionsMixin extends Vue {
  getQuestions(data: any, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_questions", { format: "json", ...data }),
      tag
    );
  }

  getQuestion(id: number | string, tag: string) {
    return this.$httpGet(
      this.$urlFor("v1_admin_question", { format: "json", id }),
      tag
    );
  }

  destroyQuestion(id: number | string, tag: string) {
    return this.$httpDelete(this.$urlFor("v1_admin_question", id), tag);
  }

  createQuestion(data: any, tag: string) {
    return this.$httpPost(this.$urlFor("v1_admin_questions"), tag, data);
  }

  updateQuestion(data: any, tag: string) {
    return this.$httpPut(
      this.$urlFor("v1_admin_question", { id: data.id }),
      tag,
      data
    );
  }
  validateQuestionFormula(data: any, tag: string) {
    return this.$httpPost(
      this.$urlFor("validate_v1_admin_database_formula", { id: data.id }),
      tag,
      data
    );
  }
}

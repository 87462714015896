import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "databases/:databaseId",
    name: "surveys_list_old",
    redirect: (current) => {
      return {
        name: "surveys_list",
        params: { ...current.params, role: "user" },
      };
    },
    meta: { role: ["doctor"] },
  },
  {
    path: "databases/:databaseId/:role",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Survey/TableList.vue"),
    name: "surveys_list",
    meta: { role: ["doctor"] },
  },
  {
    path: "surveys/:surveyId/:role",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "views/Survey/Single.vue"),
    meta: { role: ["doctor"] },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "doctor" */ "views/Survey/Single/Show.vue"
          ),
        name: "survey_show",
      },
      {
        path: "edit",
        component: () =>
          import(
            /* webpackChunkName: "doctor" */ "views/Survey/Single/Edit.vue"
          ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "doctor" */ "views/Survey/Single/Edit/AnswersGroup.vue"
              ),
            name: "survey_edit",
          },
          {
            path: ":answersGroupId",
            component: () =>
              import(
                /* webpackChunkName: "doctor" */ "views/Survey/Single/Edit/AnswersGroup.vue"
              ),
            name: "survey_edit_group",
          },
        ],
      },
    ],
  },
];

export default routes;
